import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import axios from 'axios';
import FlagIcon from 'vue-flag-icon';
loadFonts();

// axios.defaults.baseURL = "http://localhost:8000/";
axios.defaults.baseURL = 'https://esim-back.forwebdata.com/';
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

const token = store.getters['user']['token'];
if (token == null) {
  store.dispatch('reloadData');
}

axios.interceptors.response.use(
  function (response) {
    store.commit('SET_GLOBAL_ERROR', false);
    return response;
  },
  function (error) {
    if (
      error.message === 'Network Error' ||
      (error.response && error.response.status >= 500)
    ) {
      store.commit('SET_GLOBAL_ERROR', true);
    }
    return Promise.reject(error);
  }
);

createApp(App).use(router).use(store).use(vuetify).use(FlagIcon).mount('#app');
