const object_phones = [
  {
    category: 'Samsung',
    mobile: [
      {
        value: 'Samsung Galaxy Fold',
      },
      {
        value: 'Samsung Galaxy Note20',
      },
      {
        value: 'Samsung Galaxy Note20 Ultra',
      },
      {
        value: 'Samsung Galaxy S21 5G',
      },
      {
        value: 'Samsung Galaxy S21+ 5G',
      },
      {
        value: 'Samsung Galaxy S21 Ultra 5G',
      },
      {
        value: 'Samsung Galaxy S20',
      },
      {
        value: 'Samsung Galaxy S20+',
      },
      {
        value: 'Samsung Galaxy S20 Ultra',
      },
      {
        value: 'Samsung Galaxy Z Flip',
      },
      {
        value: 'Samsung Galaxy Z Fold 2',
      },
      {
        value: 'Samsung Galaxy Z Flip 3 5G Fold',
      },
      {
        value: 'Samsung Galaxy Z Fold 3 5G',
      },
      {
        value: 'Samsung Galaxy Book 2',
      },
    ],
  },
  {
    category: 'IPhone',
    mobile: [
      {
        value: 'iPhone 13 Pro Max',
      },
      {
        value: 'iPhone 13 Pro',
      },
      {
        value: 'iPhone 13 mini',
      },
      {
        value: 'iPhone 13',
      },
      {
        value: 'iPhone 12 Pro Max',
      },
      {
        value: 'iPhone 12 Pro',
      },
      {
        value: 'iPhone 12 mini',
      },
      {
        value: 'iPhone 12',
      },
      {
        value: 'iPhone 11 Pro Max',
      },
      {
        value: 'iPhone 11 Pro',
      },
      {
        value: 'iPhone 11',
      },
      {
        value: 'iPhone XS',
      },
      {
        value: 'Phone XS Max',
      },
      {
        value: 'iPhone XR',
      },
      {
        value: 'iPhone SE 2 (2020)',
      },
    ],
  },

  {
    category: 'Ipad',
    mobile: [
      {
        value: 'iPad Pro 11″ (1ere Génération et supérieur)',
      },
      {
        value: 'iPad Pro 12.9″ (3e Gen. et supérieur)',
      },
      {
        value: 'iPad Air (3e Gen. et supérieur)',
      },
      {
        value: 'iPad (7e Gen. et supérieur)',
      },
      {
        value: 'iPad mini (5e Gen. et supérieur)',
      },
    ],
  },

  {
    category: 'Huawei',
    mobile: [
      {
        value: 'Huawei P40',
      },
      {
        value: 'Huawei P40 Pro',
      },
      {
        value: 'Huawei Mate 40 Pro',
      },
    ],
  },

  {
    category: 'Google Pixel',
    mobile: [
      {
        value: 'Pixel 6',
      },
      {
        value: 'Pixel 6 Pro',
      },
      {
        value: 'Pixel 5',
      },
      {
        value: 'Pixel 4',
      },
      {
        value: 'Pixel 4a',
      },
      {
        value: 'Pixel 4 XL',
      },
      {
        value: 'Pixel 3',
      },
      {
        value: 'Pixel 3a',
      },
      {
        value: 'Pixel 3XL',
      },
      {
        value: 'Pixel 3a XL',
      },
    ],
  },
  {
    category: 'Oppo',
    mobile: [
      {
        value: 'Oppo Find X3 Pro',
      },
      {
        value: 'Oppo Reno 5 A',
      },
      {
        value: 'Oppo A55s 5G',
      },
    ],
  },

  {
    category: 'Aquos',
    mobile: [
      {
        value: 'AQUOS sense4 lite',
      },
    ],
  },

  {
    category: 'Nuu Mobile',
    mobile: [
      {
        value: 'NUU X5',
      },
    ],
  },

  {
    category: 'Panasonic',
    mobile: [
      {
        value: 'Panasonic Toughbook G2',
      },
      {
        value: 'Panasonic Toughbook 55',
      },
    ],
  },
  {
    category: 'Rakuten',
    mobile: [
      {
        value: 'Rakuten Mini',
      },
      {
        value: 'Rakuten BigS',
      },
      {
        value: 'Rakuten Big',
      },
    ],
  },
  {
    category: 'Microsoft',
    mobile: [
      {
        value: 'Surface Duo',
      },
      {
        value: 'Surface Go 2',
      },
      {
        value: 'Surface Go 3',
      },
      {
        value: 'New Surface Pro 7+',
      },
      {
        value: 'Surface Pro LTE',
      },
      {
        value: 'Surface Pro X',
      },
      {
        value: 'Surface Pro 5 LTE Advanced (Ubigi est déjà préinstallé)',
      },
      {
        value: 'Surface Pro 8',
      },
    ],
  },

  {
    category: 'Gemini',
    mobile: [
      {
        value: 'PDA Gemini',
      },
    ],
  },
];

export default object_phones;
