<template>
  <main-layout>
    <main id="main">
      <v-container style="margin-top: 150px" class="p-2">

        <v-snackbar v-model="snackbar" :color="snackbarColor" :open-delay="5000" :vertical="false" position="sticky"
          location="top">
          {{ message }}
        </v-snackbar>
        <v-row>
          <v-col md="5" class="p-2">
            <div class="text-center">
              <div class="about-content mb-15" data-aos="fade-left" data-aos-delay="100">
                <h3 style="color: grey">
                  Faite une demande de carte eSIM Moov Africa
                </h3>
              </div>
              <img src="assets/img/img-1-carte-esim-1.webp" alt="About" width="400" class="mx-auto" data-aos="zoom-in"
                data-aos-delay="100" style="margin: auto" />
            </div>
          </v-col>
          
          <v-col md="7">
            <h3 style="color: #005ba4;margin-bottom: 10px;">
              Veuillez remplir le formulaire ci-dessous
            </h3>
            <v-form ref="form" class="p-1 mb-3" v-model="valid" lazy-validation>
              <span class="font-weight-regular">Nom</span>
              <v-text-field type="text" variant="outlined" :rules="lastnameRules" v-model="lastname"
                @blur="v$.lastname.$touch()" required></v-text-field>
              <span class="font-weight-regular">Prénom(s)</span>
              <v-text-field type="text" variant="outlined" v-model="firstname" :rules="firstnameRules"
                @blur="v$.firstname.$touch()" required></v-text-field>

              <span class="font-weight-regular">Adresse </span>
              <v-text-field type="text" v-model="adress" variant="outlined" :rules="adressRules"
                @blur="v$.adress.$touch()" required></v-text-field>


              <span class="font-weight-regular">Votre Numéro de téléphone </span>

              <v-text-field v-model="moov_number" variant="outlined" :rules="moovNumberRules" @keypress="filter($event)"
                maxlength="8" @blur="v$.moov_number.$touch()" required></v-text-field>


              <span class="font-weight-regular mb-2">Êtes vous déjà abonné moov ?</span>
              <v-radio-group v-model="check_have" inline :rules="[v => !!v || 'Ce champ est obligatoire']">
                <v-radio label="Oui" color="#005BA4" value="true"></v-radio>
                <v-radio label="Non" color="#005BA4" value="false"></v-radio>
              </v-radio-group>
              <span v-if="has_number" class="font-weight-regular mb-4">Votre numéro de téléphone moov</span>

              <v-row v-if="has_number" class="mt-4">
                <v-col class="pb-0 pt-4" cols="2" sm="2">
                  <flag style="border-radius: 2px; font-size: 25px" iso="bj" class="mr-2 white-text" />
                  <span style="font-size: 14px">
                    229</span>
                </v-col>
                <v-col class="pb-0 pt-1" cols="10" sm="10">
                  <v-text-field class="pb-0" color="grey" variant="outlined" v-model="phonenumber"
                    :append-inner-icon="phone_icon_moov" @keypress="filter($event)" maxlength="8"
                    @blur="v$.phonenumber.$touch()" :rules="phonenumberRules">
                    <template v-if="img_src_moov != null" v-slot:append-inner>
                      <img width="35" height="35" v-bind:src="img_src_moov" style="border-radius: 10%" />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="(network == 'mtn' || network == 'none') && check_have == 'Oui'">
                <v-alert variant="tonal" type="error">
                  Veuillez entrez un numéro moov commençant par : 95, 55 ...
                </v-alert>
              </v-row>
              <v-row>
                <v-col md="12">
                  <span class="font-weight-regular mt-4 mb-2"> Les choix disponibles :
                  </span>
                  <v-radio-group v-model="choice"   :rules="[v => !!v || 'Ce champ est obligatoire']">
                    <v-radio v-if="has_number" label="Voulez vous conserver votre numéro moov pour la esim
                              ?" color="#005BA4" value="use_old"></v-radio>  
                    <v-radio label=" Voulez vous faire une portabilité de sim
                              ?" color="#005BA4" value="want_portability"></v-radio>  
                    <v-radio label="Voulez vous acheter un
                              nouveau
                              numéro eSim
                              ?" color="#005BA4" value="want_buy"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <span class="font-weight-regular">Modèle de votre téléphone</span>
              <v-autocomplete variant="outlined" v-model="typephone" :rules="[v => !!v || 'Ce champ est obligatoire']"
                @blur="v$.typephone.$touch()" required clearable :disabled="isUpdating" :items="typeList"
                item-value="value" item-title="title">
                <template v-slot:item="{ props, item }">
                  <v-list-item class="item-title" v-if="item.raw.header" v-bind="props" :title="item.raw.header"
                    :disabled="true"></v-list-item>
                  <v-list-item v-else v-bind="props" :title="item.raw.title">
                    <v-divider thickness="2" v-if="item.raw.divider === true" /></v-list-item>
                </template>
              </v-autocomplete>

              <v-btn v-if="has_phone_model" @click="submit" color="#F07C01" :loading="isLoading"
                :disabled="isLoading || !can_submit" class="btn mt-3 text-white">Valider</v-btn>
            </v-form>
            <v-alert v-if="!has_phone_model" text="" type="info" variant="tonal">
              <div>
                Désolé mais votre model de téléphone n'est pas compatible avec
                la eSim. <br />
                Nous avons des téléphones compatibles que nous pouvons vous
                proposez de prendre. <br />
                Voulez vous qu'on vous contact pour l'achat d'un modèle de
                téléphone compatible
              </div>
              <div class="d-flex flex-row align-center">
                <v-btn :loading="isLoading" :disabled="isLoading || !can_submit" color="success m-2"
                  @click="newDemand(true)">
                  Oui
                </v-btn>
                <v-btn :loading="isLoading" :disabled="isLoading || !can_submit" color="danger" class="text-white m-2"
                  @click="newDemand(false)">
                  Non
                </v-btn>
              </div>
            </v-alert>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="dialog" center persistent max-width="500px">
            <v-card>
              <v-card-text>
                <v-alert variant="tonal" type="info">
                  Votre demande est bien enregistrée sous ce code :
                  <span style="font-weight: 900;font-size: 20px;"> {{ response_code }} </span>
                  <br />
                  Veuillez ne pas perdre le code de la demande !
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="close_modal">
                  Copier code
                  <v-icon> mdi-file</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </main>
  </main-layout>
</template>

<script>
import MainLayout from "./MainLayout.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, minLength } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import mtn_logo from "../assets/mtn.svg";
import moov_logo from "../assets/moov.svg";
import phone_types from "../../constant_data.js";
const nameRegex = /^(?![0-9]*$)[a-zA-Z0-9]|[à-ü]|[À-Ü]+$/;

export default {
  name: "DemandView",
  components: {
    "main-layout": MainLayout,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      firstname: { required, nameRegex },
      lastname: { required, nameRegex },
      phonenumber: { required, numeric, minLength: minLength(8) },
      moov_number: { required, numeric, minLength: minLength(8) },
      adress: { required, nameRegex },
      typephone: { required },
      codedemand: { required }
    };
  },
  data() {
    return {
      valid: true,
      country: 'bj',
      img_src: null,
      img_src_moov: null,
      choice: null,
      phone_icon: "mdi-phone",
      phone_icon_moov: "mdi-phone",
      mtn_src: mtn_logo,
      moov_src: moov_logo,
      dialog: false,
      has_phone_model: true,
      overlay: false,
      moov_number: null,
      payment_link: "",
      show1: false,
      typephone: null,
      adress: null,
      isLoading: false,
      phonenumber: "",
      lastname: "",
      has_number: null,
      check_have: null,
      firstname: "",
      snackbar: false,
      snackbarColor: "default",
      message: "",
      isUpdating: false,
      typeList: phone_types,
      codedemand: null,
      response_code: null,
      available_country: [],
      available_prefix: [],
      network: null,
      lastnameRules: [
        v => !!v || 'Votre nom est obligatoire',
        v => (nameRegex.test(v)) || 'Veuilez entrer un nom valide ',

      ], firstnameRules: [
        v => !!v || 'Votre prénom est obligatoire',
        v => (nameRegex.test(v)) || 'Veuilez entrer un prénom valide',

      ],
      adressRules: [
        v => !!v || 'Votre adresse est obligatoire',
        v => (nameRegex.test(v)) || 'Veuilez entrer un nom valide ',

      ],
      moovNumberRules: [
        v => !!v || 'Votre numero de téléphone est obligatoire',
        v => (v && v.length <= 8) || 'Votre numéro doit être au moins de 8 chiffres',

      ],
      phonenumberRules: [
        v => !!v || 'Votre numero de téléphone est obligatoire',
        v => (v && v.length <= 8) || 'Votre numéro doit être au moins de 8 chiffres',

      ],

    };
  },

  mounted() {
    // window.addEventListener("message", this.receiveMessage);
    this.getPrefix()

  },

  beforeUnmount() {
    window.removeEventListener("message", this.receiveMessage);
  },
  methods: {
    async getPrefix() {
      await this.$store
        .dispatch("getPrefix")
        .then(() => {
          this.available_prefix = this.listPrefix.filter(
            (element) => element.country == this.country
          );
          this.available_country = this.listPrefix.reduce(
            (previous, current) => {

              if (previous.length != 0 &&
                previous.some(
                  (old) => old.flag == current.country && old.code == current.code
                )
              ) {
                return previous;
              }
              return [
                ...previous, {
                  code: current.code,
                  flag: current.country
                }
              ];
            },
            []
          );
        })
        .catch((error) => {
          console.log(error);
          return { error: true };
        });
    },
    filter(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect.split(" ").join(""))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    close_modal() {
      navigator.clipboard.writeText(this.response_code);
      // alert("Copied the text: " + this.response_code);
      this.dialog = false;
      // window.location.reload();
    },

    receiveMessage(event) {
      console.log(event.data);
    },
    async newDemand(option) {
      let status = option == true ? "WANT_PHONE" : "REJECTED";
      this.isLoading = true;
      const data = new FormData();
      data.append("firstname", this.firstname);
      data.append("lastname", this.lastname);
      data.append("phonenumber", this.phonenumber);
      data.append("type_phone", this.typephone);
      data.append("adress", this.adress);
      data.append("moov_number", this.moov_number);
      data.append("status", status);
      if (this.can_submit) {
        await this.$store
          .dispatch("application", data)
          .then((response) => {
            this.isLoading = false;
            if (response.data.success) {
              //let token = response.data.token;
              this.snackbar = true;
              this.snackbarColor = "green";
              this.message = "Enregistement réussi !";
              this.reset();
              setTimeout(function () {
                window.location.reload();
              }, 4000);
            } else {
              this.snackbar = true;
              this.snackbarColor = "red";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarColor = "red";
            this.message = `Erreur : ${error}`;
          });
      }
      this.isLoading = false;
      this.has_phone_model = true;
      this.typephone = null;

    },
    async submit() {
      this.isLoading = true;
      const data = new FormData();
      data.append("firstname", this.firstname);
      data.append("lastname", this.lastname);
      data.append("phonenumber", this.phonenumber);
      data.append("type_phone", this.typephone);
      data.append("adress", this.adress);
      data.append("moov_number", this.moov_number);
      data.append("want_portability", this.choice);
      data.append("use_old_sim", this.choice);
      if (this.can_submit) {
        await this.$store
          .dispatch("application", data)
          .then((response) => {
            this.isLoading = false;
            if (response.data.success) {
              this.dialog = true;
              this.response_code = response.data.data.code_demand
              this.$refs.form.reset();
              this.$refs.form.resetValidation();
              console.log(this.$refs.form)
              // let token = response.data.data.token;
              // if (token) {
              //   this.snackbar = true;
              //   this.snackbarColor = "green";
              //   this.message =
              //     "Enregistement réussi ! Veuillez passer au payment";
              //   window.location.href = `https://sandbox.fontmpay.webcoom.net/?token=${token}`;
              // }
            } else {
              this.snackbar = true;
              this.snackbarColor = "red";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarColor = "red";
            this.message = `Erreur : ${error}`;
          });
      }
      this.isLoading = false;

    },

    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    getNetworknumber(prefix) {
      prefix = parseInt(prefix);
      let name;
      for (var key in this.available_prefix) {
        if (this.available_prefix[key].prefix == prefix) {
          name = this.available_prefix[key].name;
        }
      }
      if (name == "moov" || name == "mtn") {
        return name;
      } else {
        return null;
      }
    },

  },
  watch: {
    country(value) {
      this.moov_number = null;
      if (value) {
        this.available_prefix = this.listPrefix.filter(
          (element) => element.country == value
        );
      }
    },
    phonenumber(val) {
      if (val) {
        if (this.getNetworknumber(val.substring(0, 2)) == "mtn") {
          this.img_src_moov = this.mtn_src;
          this.phone_icon_moov = null;
          this.network = "mtn"
        } else if (
          this.getNetworknumber(val.toString().substring(0, 2)) == "moov"
        ) {
          this.img_src_moov = this.moov_src;
          this.phone_icon_moov = null;
          this.network = "moov"
        } else {
          this.phone_icon_moov = "mdi-phone";
          this.img_src_moov = null;
          this.network = 'none'
        }
      } else {
        this.phone_icon_moov = "mdi-phone";
        this.img_src_moov = null;
        this.network = 'none'

      }

    },
    moov_number(val) {

      if (val) {
        if (this.getNetworknumber(val.substring(0, 2)) == "mtn") {
          this.img_src = this.mtn_src;
          this.phone_icon = null;
        } else if (
          this.getNetworknumber(val.toString().substring(0, 2)) == "moov"
        ) {
          this.img_src = this.moov_src;
          this.phone_icon = null;
        }
      } else {
        this.phone_icon = "mdi-phone";
        this.img_src = null;
      }
    },
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
    errors(value) {
      if (value["phonenumber"]) {
        this.message = "Le numeré de téléphone a une demande en cours déjà ";
        this.snackbar = true;
        this.snackbarColor = "red";
      }
    },

    typephone(value) {
      if (value === "autre") {
        this.has_phone_model = false;
      } else {
        this.has_phone_model = true;
      }
    },

    check_have(value) {
      this.choice = null;
      this.typephone = null
      if (value === "true") {
        this.has_number = true;
      } else {
        this.has_number = false;
        this.phonenumber = null;
      }
    },
  },
  computed: {
    can_submit() {
      if (
        !this.v$.firstname.$invalid &&
        !this.v$.lastname.$invalid &&
        !this.v$.adress.$invalid &&
        !this.v$.moov_number.$invalid &&
        !this.v$.phonenumber.$invalid &&
        !this.v$.typephone.$invalid &&
        this.has_number == true && this.choice != null && this.network == "moov"
      ) {
        return true;
      } else if (
        !this.v$.firstname.$invalid &&
        !this.v$.lastname.$invalid &&
        !this.v$.adress.$invalid &&
        !this.v$.moov_number.$invalid &&
        !this.v$.typephone.$invalid &&
        this.has_number == false && this.choice != null
      ) {

        return true;
      }
      else {
        return false;
      }
    },


    ...mapGetters(["registered", "errors", "user", "listPrefix"]),
  },
};
</script>
<style>
#custum-btn {
  color: white;
  background-color: #005ba4;
  transition: all 1s;
}

#custum-btn:hover {
  background-color: #f07c01;
}

.item-title {
  color: white;
  background-color: #005ba4;
  font-weight: 600;
  font-size: 16px;
}
</style>
