<template>
  <main-layout>
    <main id="main">
      <section id="hero" class="hero-container text-center">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-lg-6 col-lg-6 justify-content-center">
          
            <v-carousel color="#005aa4e7" interval="15000" cycle  height="auto" :show-arrows="false"  :show-arrows-on-hover="false" hide-delimiter-background>
            <v-carousel-item  >
                  <img src="assets/img/esim/img1.jpg" alt="About" data-aos="zoom-in" data-aos-delay="100" />
            </v-carousel-item>
            <v-carousel-item  >
                  <img src="assets/img/esim/img2.jpg" alt="About" data-aos="zoom-in" data-aos-delay="100" />
            </v-carousel-item>
          </v-carousel>
          </div>
          <div class="col-md-12 col-sm-12 col-lg-6 col-lg-6" style="padding: 20px; line-height: 5px">
            <h1> 

              Obtenez votre <span style="color: #f07c01">eSim</span> MOOV AFRICA BENIN ici
            </h1>
            <h2>Un monde nouveau vous appelle...</h2>
            <v-btn id="demande-btn" class="text-white" height="50" width="400" @click="goDemand">Faire une demande de
              esim</v-btn>
          </div>
        </div>
      </section>
      <!-- End Hero Section -->
      <!-- ======= About Us Section ======= -->
      <section id="about-us" class="about-us padd-section">
        <div class="container" data-aos="fade-up">
          <div class="section-title text-center">
            <h3 style="font-weight: 200">
              Comment activer sa carte eSIM Moov Africa ?
            </h3>
            <!-- <p class="separator">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p> -->
          </div>

          <v-carousel color="#005aa4e7" interval="10000" cycle height="auto" hide-delimiter-background
            show-arrows="hover">
            <v-carousel-item class="mb-10">
              <div class="row justify-content-center">
                <div class="col-md-5 col-lg-3">
                  <img src="assets/img/img-1-carte-esim-1.webp" alt="About" data-aos="zoom-in" data-aos-delay="100" />
                </div>

                <div class="col-md-7 col-lg-5">
                  <div class="about-content" data-aos="fade-left" data-aos-delay="100">
                    <h4>
                      La procédure à suivre pour activer sa carte eSIM de Moov
                      Africa
                    </h4>
                    <p>
                      Pour obtenir une carte SIM, il est nécessaire de disposer d’un
                      smartphone lui-même équipé d’une carte eSIM. Cela concerne les
                      derniers mobiles Apple, Samsung ou encore Huawei et Google. En
                      plus de cela, les puces eSIM se trouvent également dans
                      certains objets connectés, principalement dans des montres de
                      type Apple Watch ou Galaxy Watch. Cela permet à ces objets de
                      profiter eux-aussi du forfait mobile souscrit par l’abonné.
                      Activer sa puce eSIM permet donc de profiter de données
                      mobiles sur smartphone et smartwatch, sans avoir besoin
                      d’utiliser ou changer de carte SIM physique.
                    </p>
                  </div>
                </div>
              </div>
            </v-carousel-item>
            <v-carousel-item class="mb-10">
              <div class="row justify-content-center">
                <div class="col-md-7 col-lg-5">
                  <div class="about-content" data-aos="fade-left" data-aos-delay="100">
                    <h4>
                      Quels sont les prérequis pour profiter d’une eSIM chez Moov
                      Africa ?
                    </h4>
                    <p>
                      Les cartes eSIM ne sont naturellement utilisables qu’avec les
                      mobiles équipés de la puce en question. Moov Africa permet de
                      commander une carte eSIM immédiatement avec son mobile, sans
                      passer au préalable par une carte SIM classique.
                    </p>
                  </div>
                </div>
                <div class="col-md-5 col-lg-3">
                  <img src="assets/img/img-2-prerequis-esim-1.webp" alt="About" data-aos="zoom-in"
                    data-aos-delay="100" />
                </div>
              </div>
            </v-carousel-item>
            <v-carousel-item class="mb-10">
              <div class="row justify-content-center">
                <div class="col-md-5 col-lg-3">
                  <img src="assets/img/forfait-eSIM-img1-1.webp" alt="About" data-aos="zoom-in" data-aos-delay="100" />
                </div>
                <div class="col-md-7 col-lg-5">
                  <div class="about-content" data-aos="fade-left" data-aos-delay="100">
                    <h4>
                      Comment faire pour profiter de l’eSIM avec un forfait Moov Africa ?
                    </h4>
                    L’utilisateur qui souhaite utiliser une carte eSIM doit ainsi :
                    <p>

                      <span style="font-weight: 900!important;">. </span> disposer d’un mobile équipé d’une carte SIM
                      embarquée
                      <br>
                      <span>. </span> souscrire un forfait mobile internet <br>
                      <span>. </span> à la souscription, opter pour la carte eSIM <br>

                    </p>
                    <h4>SIM ou eSIM, que choisir ?</h4>
                    <p>
                      L’activation de la carte eSIM n’a rien d’obligatoire pour
                      l’abonné. Il peut tout à fait opter pour une carte SIM
                      classique s’il le souhaite. De fait, les premiers smartphones
                      eSIM disposent également du compartiment dédié à la carte SIM
                      et à la carte SD pour les extensions de mémoire. Par ailleurs,
                      il reste parfaitement possible d’avoir deux cartes SIM sur un
                      même mobile pour, par exemple, avoir deux numéros de
                      téléphone.
                    </p>
                  </div>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </section>
      <!-- End About Us Section -->

      <section id="pricing" class="padd-section text-cente">
        <div class="container" data-aos="fade-up">
          <div class="section-title text-center">
            <h2>Téléphones compatible esim</h2>
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="mx-auto col-md-8 col-lg-8">
              <v-tabs v-model="tab" bg-color="#F07C01" color="white" grow>
                <v-tab v-for="item in items" :key="item" :value="item">
                  {{ item }}
                </v-tab>
              </v-tabs>

              <v-window v-model="tab">
                <v-window-item v-for="item in items" :key="item" :value="item">
                  <v-card color="grey lighten-1" flat>
                    <v-card-text class="text-center p-1"><v-list lines="one">
                        <v-list-item v-for="item in current_list" :key="item.value" :title="item.value">
                          <v-divider color="black" thickness="1"></v-divider></v-list-item> </v-list></v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
            </div>
          </div>
        </div>
      </section>
      <!-- phone compatible -->

      <!-- ======= Pricing Section ======= -->
      <section id="pricing" class="padd-section text-cente">
        <div class="container" data-aos="fade-up">
          <div class="section-title text-center">
            <h2>Nos offres</h2>
            <p class="separator">
              Combien coûte l’activation d’une carte eSIM avec Moov Africa ?
            </p>
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="mx-auto col-md-8 col-lg-8">
              <v-table density="compact">
                <thead>
                  <tr style="background-color: var(--orange); color: white">
                    <th class="text-left"></th>
                    <th colspan="1" class="text-left">Sim Swap</th>
                    <th colspan="1" class="text-left">Achat Nouvelle eSim</th>
                    <th colspan="1" class="text-left">Avantages</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background-color: #ececec">
                    <td></td>
                    <td>Prix</td>
                    <td>Prix</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Grand Public et HVCC (numéro classique)</td>
                    <td>5000 Fcfa</td>
                    <td>5000 Fcfa</td>
                    <td>5000 crédit</td>
                  </tr>
                  <tr style="background-color: #ececec">
                    <td>Grand Public et HVCC (numéro Paire)</td>
                    <td>5000 Fcfa</td>
                    <td>5000 Fcfa</td>
                    <td>5000 crédit</td>
                  </tr>

                </tbody>
              </v-table>
            </div>
          </div>
        </div>
      </section>
      <!-- End Pricing Section -->

      <!-- ======= Contact Section ======= -->
      <section id="contact" class="padd-section">
        <div class="container" data-aos="fade-up">
          <div class="section-title text-center">
            <h2>Contactez nous</h2>
            <!-- <p class="separator">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p> -->
          </div>

          <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-3 col-md-4">
              <div class="info">
                <div>
                  <i class="bi bi-geo-alt"></i>
                  <p>Cotonu, Bénin</p>
                </div>

                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <p>esim@moov-africa.bj </p>
                </div>

                <div>
                  <i class="bi bi-phone"></i>
                  <p> 1919
                  </p>
                </div>

                <div>
                  <img src="assets/img/icons8-whatsapp-48.png" alt="whatsapp number" data-aos-delay="50" width="30" />
                  <span style="color: #505050;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0.5px;
                    margin-left: 18px;
                    margin-bottom: 20px">
                    55505005
                  </span>
                </div>
              </div>

              <!-- <div class="social-links">
                <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                <a href="#" class="instagram"
                  ><i class="bi bi-instagram"></i
                ></a>
                <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
              </div> -->
            </div>
            <v-snackbar v-model="snackbar" :color="snackbarColor" :open-delay="2000" :vertical="false" position="sticky"
              location="bottom">
              {{ snack_message }}
            </v-snackbar>
            <div class="col-lg-5 col-md-8">
              <v-form ref="contact" v-model="valid" lazy-validation class="p-1">
                <v-text-field type="text" variant="outlined" v-model="name" :rules="nameRules" @blur="v$.name.$touch()"
                  required placeholder="Nom et Prénom"></v-text-field>
                <v-text-field type="text" variant="outlined" v-model="email" :rules="emailRules"
                  @blur="v$.email.$touch()" required placeholder="Email"></v-text-field>
                <v-text-field type="text" variant="outlined" v-model="subject" :rules="subjectRules"
                  @blur="v$.subject.$touch()" required placeholder="Raison du mail"></v-text-field>
                <v-textarea type="text" variant="outlined" v-model="message" :rules="messageRules"
                  @blur="v$.message.$touch()" required placeholder="Message"></v-textarea>

                <div class="text-left">
                  <v-btn color="#005BA4" :loading="isLoading" class="text-white" @click="submit">
                    Envoyer le message
                  </v-btn>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </section>
      <!-- End Contact Section -->
    </main>
    <!-- End #main -->
  </main-layout>
</template>

<script>
// Components

import MainLayout from "./MainLayout.vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
const nameRegex = /^(?![0-9]*$)[a-zA-Z0-9]|[à-ü]|[À-Ü]+$/;
const emailReguex = /^([a-zA-Z][\w+-]+(?:\.\w+)?)@([\w-]+(?:\.[a-zA-Z]{2,10})+)$/

import object_phones from "../../object_phones";

export default {
  name: "HomeView",
  components: {
    "main-layout": MainLayout,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      email: { required, email },
      name: { required, nameRegex },
      subject: { required, nameRegex },
      message: { required, nameRegex },
    };
  },
  data() {
    return {
      valid: true,
      name: null,
      email: null,
      subject: null,
      message: null,
      isLoading: false,
      snackbar: false,
      snackbarColor: "default",
      snack_message: "",
      tab: null,
      items: [
        "Samsung",
        "IPhone",
        "Ipad",
        "Huawei",
        "Google Pixel",
        "Oppo",
        "Aquos",
        "Panasonic",
        "Rakuten",
        "Microsoft",
        "Gemini",
      ],
      emailRules: [
        v => !!v || 'Votre email est obligatoire',
        v => (emailReguex.test(v)) || 'Veuilez entrer un mail valide',
      ],
      nameRules: [
        v => !!v || 'Votre nom est obligatoire',
        v => (nameRegex.test(v)) || 'Veuilez entrer un nom valide',
      ],
      subjectRules: [
        v => !!v || "L'objet du mail est obligatoire",
        v => (nameRegex.test(v)) || 'Veuilez entrer un objet valide',
      ],
      messageRules: [
        v => !!v || "Le message du mail est obligatoire",
        v => (nameRegex.test(v)) || 'Veuilez entrer un message valide',
      ],
      current_list: [],
      list_phone: object_phones,
    };
  },
  created() { },

  methods: {
    goDemand() {
      this.$router.push({
        name: "demand",
      });
    },
    async submit() {
      this.isLoading = true;
      this.v$.$touch();
      const data = new FormData();
      data.append("name", this.name);
      data.append("email", this.email);
      data.append("raison", this.subject);
      data.append("message", this.message);

      if (!this.v$.$invalid) {
        await this.$store
          .dispatch("contact", data)
          .then((response) => {
            this.isLoading = false;
            if (response) {
              this.snackbar = true;
              this.snackbarColor = "green";
              this.snack_message = "Email envoyé avec succès";
              this.reset();
              setTimeout(function () {
                window.location.reload();
              }, 4000);
            } else {
              this.snackbar = true;
              this.snackbarColor = "red";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarColor = "red";
            this.snack_message = `Erreur : ${error}`;
            console.error(error);
          });
      }
      this.isLoading = false;
    },
    reset() {
      this.$refs.contact.reset();
      this.$refs.contact.resetValidation();
    },
  },
  watch: {
    tab(newValue) {
      let result = this.list_phone.filter(function (element) {
        return element.category == newValue;
      });
      this.current_list = result[0].mobile;
    },
  },
  computed: {
    ...mapGetters(["authenticated"]),
  },
};
</script>

<style scoped>
#demande-btn {
  color: #005ba4 !important;
  font-weight: 700 !important;
  text-transform: none;
  font-size: 20px;
  border-radius: 6px;
  border: medium solid #005ba4;
  transition: all 0.5s;
}

#demande-btn:hover {
  background-color: #005ba4 !important;
  color: white !important;
  font-weight: 700 !important;
  text-transform: none;
  font-size: 20px;
  border-radius: 6px;
  padding: 10px 10px 10px 10px;
  border: medium solid #f07c01;
}
</style>
