<template>
  <header id="header" class="header fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center justify-content-between">
      <div id="logo">
        <a href="/"><img style="width: 100px" src="assets/img/logo-moov.png" alt="" title="" /></a>
      </div>
      <nav id="navbar" class="navbar">
        <ul>
          <li>
            <router-link color="#005BA4" class="col" class-active="active" to="/" exact>Acceuil</router-link>
          </li>
          <li>
            <router-link class="col" class-active="active" to="/demand" exact>Nouvelle demande </router-link>
          </li>
          <li>
            <router-link class="col" class-active="active" to="/search-demand" exact>Rechercher demande </router-link>
          </li>
          <!-- <li>
            <router-link class="col" class-active="active" to="" exact
              >Contact</router-link
            >
          </li> -->
          <!-- <li v-if="authenticated">
            <router-link
              class="col"
              class-active="active"
              to="/info-conso"
              exact
              >Info Conso</router-link
            >
          </li> -->
          <!-- <li v-if="!authenticated">
            <router-link class="col" class-active="active" to="/sign-in" exact
              >Connexion</router-link
            >
          </li> -->
          <!-- <li v-if="authenticated">
            <a @click="logout" class="nav-link" exact>Deconnexion</a>
          </li> -->
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderComponent",
  data: () => ({}),
  watch: {
    authenticated(value) {
      if (!value) {
        this.$router.push({ path: "/sign-in" });
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      console.log("logout");
    },
  },
  computed: {
    ...mapGetters(["authenticated", "user"]),
  },
};
</script>
<style>
.router-link-exact-active {
  background-color: #005aa4e7;
  border-radius: 3px;
  color: white !important;
  margin: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
}

.navbar a,
.navbar a:focus {
  padding: 10px;
  text-transform: none;
}

.nav-link:hover {
  cursor: pointer;
}
</style>
