import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
// import SignInView from '../views/auth/SignInView.vue';
// import SignUpView from '../views/auth/SignUpView.vue';
import DemandView from '../views/DemandView.vue'; 
import SearchDemand from '../views/SearchDemand.vue';
// import InfoConsoView from '../views/InfoConsoView.vue';
// import store from '../store/index.js';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'About',
    },
  },
  // {
  //   path: '/sign-in',
  //   name: 'sign-in',
  //   component: SignInView,
  //   meta: {
  //     title: 'Sign-in',
  //     middleware: 'guest',
  //   },
  // },
  // {
  //   path: '/sign-up',
  //   name: 'sign-up',
  //   component: SignUpView,
  //   meta: {
  //     title: 'Sign-up',
  //     middleware: 'guest',
  //   },
  // },
  {
    path: '/demand',
    name: 'demand',
    component: DemandView,
    meta: {
      title: 'Demand',
      middleware: 'auth',
    },
  },
  {
    path: '/search-demand',
    name: 'search_demand',
    component: SearchDemand,
    meta: {
      title: 'SearchDemand',
      middleware: 'auth',
    },
  },
 
  // {
  //   path: '/info-conso',
  //   name: 'info-cons',
  //   component: InfoConsoView,
  //   meta: {
  //     title: 'InfoConso',
  //     middleware: 'auth',
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   document.title = `eSim - ${to.meta.title}`;
//   store.dispatch('getUser');
//   if (to.meta.middleware == 'auth') {
//     if (store.state.authenticated) {
//       next();
//     } else {
//       router.push({ path: '/sign-in' });
//     }
//   } else if (to.meta.middleware == 'guest') {
//     if (store.state.authenticated) {
//       router.push({ path: '/' });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
