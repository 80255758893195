<template>
  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-lg-7">
          <div class="footer-logo">
            <a class="navbar-brand" href="#">esim Moov Africa</a>
            <p class="text-justify" style="font-size: 14px">
              La eSim est une sim virtuelle qui n’est compatible qu’avec certains type de téléphone de dernière
              génération.
              Cette technologie fournit tous les services que vous fournissent la sim physique à la différence qu’elle
              n’est pas touchable.
              Elle vous évite de vous encombrer dans vos déplacement et vous facilite une prise en main de votre ligne
              téléphonique.
            </p>
          </div>
        </div>

        <div class=" col-md-5 col-lg-5">
          <div class="footer-logo">
            <p class="text-justify" style="font-size: 14px!important;text-transform: none!important;"> Pour
              plus
              d’information au
              besoin <br>
              Contactez nous via <span style="color:rgb(32 164 255);text-transform: none ;">esim@moov-africa.bj</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="copyrights">
      <div class="container">
        <p>&copy; Copyrights webcoom. All rights reserved.</p>
        <div class="credits">
          Designed by
          <a href="https://www.webcoom.net"
            ><span style="color: red">Webcoom</span>
          </a>
        </div>
      </div>
    </div> -->
  </footer>
  <!-- End  Footer -->
</template>

<script>
export default {
  name: "FooterComponent",

  data: () => ({}),
};
</script>
<style>
/* #footer {
  margin-top: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
} */
</style>
