<template>
  <main-layout>
    <main id="main">
      <v-container fill-height fluid style="height: 80vh; margin-top: 100px" class="p-5">
        <v-snackbar v-model="snackbar" :color="snackbarColor" :open-delay="5000" :vertical="false" position="sticky"
          location="top">
          {{ message }}
        </v-snackbar>
        <v-form ref="search_demand" class="p-1" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="12" class="text-left">
              <h3 style="color: #005ba4" class="mt-5">
                Rechercher une demande avec son code ou votre numéro moov
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5" class="text-left">
              <v-text-field type="text" label="Code de la demande / Numéro Moov" variant="outlined" v-model="codedemand"
                :rules="codedemandRules" @blur="v$.codedemand.$touch()" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5" class="text-left">
              <v-btn @click="searchDemand(codedemand)" color="#005ba4" append-icon="mdi-search" class="text-white"
                :disabled="!codedemand" :loading="isLoading">
                Rechercher
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="currentDemand != null" class="mt-5">
          <v-col md="12" class="text-left">
            <h3 style="color: #005ba4" class="mt-5">
              Résultat de la recherhce
            </h3>
          </v-col>
        </v-row>
        <v-row v-if="currentDemand != null" class="mt-5">
          <v-col md="4" class="text-left">
            <v-expansion-panels>
              <v-expansion-panel v-if="currentDemand" class="mb-2">
                <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus">
                  <v-icon icon="mdi-file-outline" class="mr-4" color="#F07C01"></v-icon>
                  <strong style="color: #005ba4"> CODE DE LA DEMANDE : </strong> {{
                    currentDemand.code_demand
                  }}
                  <template v-slot:actions="{ expanded }">
                    <v-icon :color="!expanded ? '#F07C01' : '#005BA4'"
                      :icon="expanded ? 'mdi-minus' : 'mdi-plus'"></v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>

                  <strong style="color: #005ba4"> Numéro de téléphone : </strong>
                  {{
                    currentDemand.moov_number != "null"
                      ? " " + currentDemand.moov_number
                      : "Pas de numéro "
                  }}

                  <div v-if="currentDemand.status == 'PENDING' || currentDemand.status == 'REJECTED'">
                    <span style="color: #005ba4; font-weight: 700">Statut :</span>
                    {{
                      currentDemand.status == "PENDING"
                        ? "En cours de traitement"
                        : "Désolé mais votre téléphone n'est pas compatible "
                    }}
                  </div>
                  <div v-if="currentDemand.status == 'WANT_PHONE'">
                    <span style="color: #005ba4; font-weight: 700">Statut :</span>
                    Achat de téléphone
                  </div>
                  <div v-if="currentDemand.status == 'VALIDED'">
                    <span style="color: #005ba4; font-weight: 700">Processus Terminé
                    </span>
                    <v-btn @click="processPayment(currentDemand.id)" color="#005ba4" append-icon="mdi-cash"
                      class="text-white" :disabled="isLoading || !can_submit" :loading="isLoading">
                      Payer la esim
                    </v-btn>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>


      </v-container>
    </main>
  </main-layout>
</template>

<script>
import MainLayout from "./MainLayout.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";

export default {
  name: "DemandView",
  components: {
    "main-layout": MainLayout,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      codedemand: { required }
    };
  },
  data() {
    return {
      valid: true,
      overlay: false,
      moov_number: null,
      payment_link: "",
      show1: false,
      isLoading: false,
      snackbar: false,
      snackbarColor: "default",
      message: "",
      currentDemand: null,
      isUpdating: false,
      codedemandRules: [
        v => !!v || 'Le code ou le numéro est obligatoire',
      ],
      codedemand: null,
      response_code: null,
    };
  },


  methods: {

    async searchDemand(code) {
      if (code) {
        const data = new FormData();
        data.append("code_demand", code);
        await this.$store
          .dispatch("search_demand", data)
          .then((response) => {
            if (response.success) {
              this.currentDemand = response.data;
            }
            if (this.currentDemand && this.currentDemand.length == 0) {
              this.message = "Aucune demande trouvée";
              this.snackbar = true;
              this.snackbarColor = "green";
            } else {
              this.message = "Recherche bien effectuée";
              this.snackbar = true;
              this.snackbarColor = "green";
            }

          })
          .catch((error) => {
            console.error(error);
            this.currentDemand = [];
          });
      }
      this.$refs.search_demand.reset()
      this.$refs.search_demand.resetValidation()
      this.isLoading = false;
    },

    reset() {
      this.$refs.demand.reset();
      this.$refs.demand.resetValidation();
    },
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
    errors(value) {
      if (value["phonenumber"]) {
        this.message = "Le numeré de téléphone a une demande en cours déjà ";
        this.snackbar = true;
        this.snackbarColor = "red";
      }
    },
    snackbar() {
      // if (!this.errors["phonenumber"] && value === false) {
      //   console.log("existe pas" + this.errors["phonenumber"]);
      //   console.log("donc existe pas");
      // } else {
      //   console.log("donc exist" + this.errors["phonenumber"]);
      //   console.log("existe  " + this.errors["phonenumber"]);
      // }
      // if (!value && !this.errors["phonenumber"]) {
      //   window.location.reload();
      // }
    },
    typephone(value) {
      if (value === "autre") {
        this.has_phone_model = false;
      } else {
        this.has_phone_model = true;
      }
    },

    check_have(value) {
      if (value === "true") {
        this.has_number = true;
      } else {
        this.has_number = false;
        this.phonenumber = null;
      }
    },
  },
  computed: {
    codedemandErrors() {
      const errors = [];
      if (!this.v$.codedemand.$dirty) return errors;
      this.v$.codedemand.required.$invalid &&
        errors.push("Veuilez entrer le code de la demande");
      return errors;
    },

    ...mapGetters(["registered", "errors", "user"]),
  },
};
</script>
<style>
#custum-btn {
  color: white;
  background-color: #005ba4;
  transition: all 1s;
}

#custum-btn:hover {
  background-color: #f07c01;
}

.item-title {
  color: white;
  background-color: #005ba4;
  font-weight: 600;
  font-size: 16px;
}
</style>
