import { createStore } from 'vuex';
import { createApp } from 'vue';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  state: {
    authenticated: false,
    registered: false,
    user: {},
    errors: {},
    products: [],
    globalError: false,
    listPrefix: null,
    selectList: null,


  },
  getters: {
    listPrefix(state) {
      return state.listPrefix;
    },
    selectList(state) {
      return state.selectList;
    },
    products(state) {
      return state.products;
    },
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    registered(state) {
      return state.registered;
    },

    errors(state) {
      return state.errors;
    },
    globalError(state) {
      return state.globalError;
    },
  },
  mutations: {
    SET_LISTPREFIX(state, value) {
      state.listPrefix = value;
    },
    SET_SELECTLIST(state, value) {
      state.selectList = value;
    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_REGISTERED(state, value) {
      state.registered = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },

    SET_ERRORS(state, value) {
      state.errors = value;
    },
    CLEAR_ERRORS(state) {
      state.errors = {};
    },
    SET_GLOBAL_ERROR(state, value) {
      state.globalError = value;
    },
    SET_PRODUCTS(state, value) {
      state.products = value;
    },
  },
  actions: {
    async getProducts({ commit }) {
      // axios.defaults.headers.common['Authorization'] =
      //   'Bearer ' + store.getters['user']['token'];
      commit('CLEAR_ERRORS');
      var response;
      try {
        response = await axios.get('api/products');
        let list_products = response.data.data;
        commit('SET_PRODUCTS', list_products);
      } catch (e) {
        commit('SET_ERRORS', e);
      }
    },
    async getUser({ commit }) {
      await axios.get('sanctum/csrf-cookie');
      try {
        let response = await axios.post('api/me');
        let { connected } = response.data;
        let { user } = response.data;
        if (connected) {
          commit('SET_AUTHENTICATED', true);
          commit('SET_USER', user);
        } else {
          commit('SET_AUTHENTICATED', false);
          commit('SET_USER', {});
        }
      } catch (error) {
        commit('SET_AUTHENTICATED', false);
        commit('SET_USER', {});
      }
    },
    async reloadData({ commit }) {
      commit('SET_AUTHENTICATED', false);
      commit('SET_REGISTERED', false);
      commit('SET_USER', {});
      commit('CLEAR_ERRORS');
      commit('SET_GLOBAL_ERROR', {});
    },
    async clear({ commit }) {
      commit('CLEAR_ERRORS');
    },
    async setGlobalError({ commit }, payload) {
      commit('SET_GLOBAL_ERROR', payload);
    },
    async register({ commit }, payload) {
      await axios.get('sanctum/csrf-cookie');

      commit('CLEAR_ERRORS');
      var response;
      try {
        response = await axios.post('api/customers', payload);
        commit('SET_ERRORS', {});
        commit('SET_REGISTERED', true);
      } catch (e) {
        if (e.response.status === 422) {
          let errors = {
            email: e.response.data.errors['email'],
            phonenumber: e.response.data.errors['phonenumber'],
          };
          commit('SET_ERRORS', errors);
          commit('SET_REGISTERED', false);
        } else if (e.response.status === 500) {
          let errors = {
            connexion: e.response.data.errors,
          };
          commit('SET_ERRORS', errors);
          commit('SET_REGISTERED', false);
        } else {
          commit('SET_ERRORS', e.response.data);
          commit('SET_REGISTERED', false);
        }
      }
      return response;
    },
    async login({ commit }, payload) {
      commit('CLEAR_ERRORS');
      await axios.get('sanctum/csrf-cookie');
      let response;
      try {
        response = await axios.post('api/customers/sign-in', payload);
        if (response.data.success) {
          commit('SET_USER', response.data.data);
          commit('SET_AUTHENTICATED', true);
        } else {
          let errors = {};
          errors = {
            credential: response.data.data.error,
          };
          commit('SET_ERRORS', errors);
          commit('SET_USER', {});
          commit('SET_AUTHENTICATED', false);
        }
      } catch (e) {
        if (e.response.status === 422) {
          let errors = {};
          errors = {
            credential: e.response.data,
          };
          commit('SET_ERRORS', errors);
          commit('SET_AUTHENTICATED', false);
        }
      }
      return response;
    },
    async logout({ commit }) {
      await axios.get('sanctum/csrf-cookie');
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + store.getters['user']['token'];
      try {
        let response = await axios.post('api/customers/logout');
        if (response.status >= 200 && response.status <= 299) {
          commit('SET_USER', {});
          commit('SET_AUTHENTICATED', false);
        } else {
          commit('SET_ERRORS', response.data);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status == 401) {
            let { connected } = { user: null, connected: false };
            commit('SET_AUTHENTICATED', connected);
          }
        }
      }
    },
    async getPrefix({ commit }) {
      await axios.get("sanctum/csrf-cookie");
      try {
        let response = await axios.post("api/prefix");
        var list = response.data;
        var result = [];
        if (list) {
          list.forEach((item, index) => {
            result[index] = {
              code: item.code,
              flag: item.country,
              prefix: item.prefix,
            };
          });
 
          commit("SET_LISTPREFIX", response.data);
          commit("SET_SELECTLIST", result);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async application({ commit }, payload) {
      // axios.defaults.headers.common['Authorization'] =
      //   'Bearer ' + store.getters['user']['token'];
      await axios.get('sanctum/csrf-cookie');
      commit('CLEAR_ERRORS');
      var response;
      try {
        response = await axios.post('api/demands/store', payload);
        return response;
      } catch (e) {
        console.log(e);
        commit('SET_ERRORS', e);
      }
      return response;
    },
    async search_demand({ commit }, payload) {
      // axios.defaults.headers.common['Authorization'] =
      //   'Bearer ' + store.getters['user']['token'];
      await axios.get('sanctum/csrf-cookie');
      commit('CLEAR_ERRORS');
      var response ;
      try {
         response = await axios.post('api/demands', payload);
      } catch (e) {
        // if (e.response.status === 422) {
        //   let errors = {
        //     email: e.response.data.errors['email'],
        //     phonenumber: e.response.data.errors['phonenumber'],
        //   };
        //   commit('SET_ERRORS', errors);
        // }
        console.log(e)
      }
      if(response){
        return response.data;
      }else{
        return [];
      }
      
    },

    async contact({ commit }, payload) {
      commit('CLEAR_ERRORS');
      var response;
      try {
        response = await axios.post('api/customers/contact', payload);
      } catch (e) {
        console.log(e);
      }
      return response;
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
const app = createApp();
app.use(store);
export default store;
