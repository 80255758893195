const phone_types = [
  {
    header: 'Samsung',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy Fold',
    title: 'Samsung Galaxy Fold',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy Note20',
    title: 'Samsung Galaxy Note20',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy Note20 Ultra',
    title: 'Samsung Galaxy Note20 Ultra',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy S21 5G',
    title: 'Samsung Galaxy S21 5G',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy S21+ 5G',
    title: 'Samsung Galaxy S21+ 5G',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy S21 Ultra 5G',
    title: 'Samsung Galaxy S21 Ultra 5G',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy S20',
    title: 'Samsung Galaxy S20',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy S20+',
    title: 'Samsung Galaxy S20+',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy S20 Ultra',
    title: 'Samsung Galaxy S20 Ultra',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy Z Flip',
    title: 'Samsung Galaxy Z Flip',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy Z Fold 2',
    title: 'Samsung Galaxy Z Fold 2',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy Z Flip 3 5G Fold',
    title: 'Samsung Galaxy Z Flip 3 5G Fold',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy Z Fold 3 5G',
    title: 'Samsung Galaxy Z Fold 3 5G',
  },
  {
    group: 'Samsung',
    value: 'Samsung Galaxy Book 2',
    title: 'Samsung Galaxy Book 2',
  },
  { divider: true },
  { header: 'iPhone' },
  {
    group: 'iPhone',
    value: 'iPhone 13 Pro Max',
    title: 'iPhone 13 Pro Max',
  },
  { group: 'iPhone', value: 'iPhone 13 Pro', title: 'iPhone 13 Pro' },
  { group: 'iPhone', value: 'iPhone 13 mini', title: 'iPhone 13 mini' },
  { group: 'iPhone', value: 'iPhone 13', title: 'iPhone 13' },
  {
    group: 'iPhone',
    value: 'iPhone 12 Pro Max',
    title: 'iPhone 12 Pro Max',
  },
  { group: 'iPhone', value: 'iPhone 12 Pro', title: 'iPhone 12 Pro' },
  { group: 'iPhone', value: 'iPhone 12 mini', title: 'iPhone 12 mini' },
  { group: 'iPhone', value: 'iPhone 12', title: 'iPhone 12' },
  {
    group: 'iPhone',
    value: 'iPhone 11 Pro Max',
    title: 'iPhone 11 Pro Max',
  },
  { group: 'iPhone', value: 'iPhone 11 Pro', title: 'iPhone 11 Pro' },
  { group: 'iPhone', value: 'iPhone 11', title: 'iPhone 11' },
  { group: 'iPhone', value: 'iPhone XS', title: 'iPhone XS' },
  { group: 'iPhone', value: 'Phone XS Max', title: 'Phone XS Max' },
  { group: 'iPhone', value: 'iPhone XR', title: 'iPhone XR' },
  {
    group: 'iPhone',
    value: 'iPhone SE 2 (2020)',
    title: 'iPhone SE 2 (2020)',
  },
  { divider: true },
  { header: 'Ipad' },
  {
    group: 'ipad',
    value: 'iPad Pro 11″ (1ere Génération et supérieur)',
    title: 'iPad Pro 11″ (1ere Génération et supérieur)',
  },
  {
    group: 'ipad',
    value: 'iPad Pro 12.9″ (3e Gen. et supérieur)',
    title: 'iPad Pro 12.9″ (3e Gen. et supérieur)',
  },
  {
    group: 'ipad',
    value: 'iPad Air (3e Gen. et supérieur)',
    title: 'iPad Air (3e Gen. et supérieur)',
  },
  {
    group: 'ipad',
    value: 'iPad (7e Gen. et supérieur)',
    title: 'iPad (7e Gen. et supérieur)',
  },
  {
    group: 'ipad',
    value: 'iPad mini (5e Gen. et supérieur)',
    title: 'iPad mini (5e Gen. et supérieur)',
  },
  { divider: true },
  { header: 'Huawei' },
  {
    value: 'Huawei P40',
    title: 'Huawei P40',
  },
  {
    value: 'Huawei P40 Pro',
    title: 'Huawei P40 Pro',
  },
  {
    value: 'Huawei Mate 40 Pro',
    title: 'Huawei Mate 40 Pro',
  },
  { divider: true },
  { header: 'Google Pixel' },
  {
    value: 'Pixel 6',
    title: 'Pixel 6',
  },
  {
    value: 'Pixel 6 Pro',
    title: 'Pixel 6 Pro',
  },
  {
    value: 'Pixel 5',
    title: 'Pixel 5',
  },
  {
    value: 'Pixel 4',
    title: 'Pixel 4',
  },
  {
    value: 'Pixel 4a',
    title: 'Pixel 4a',
  },
  {
    value: 'Pixel 4 XL',
    title: 'Pixel 4 XL',
  },
  {
    value: 'Pixel 3',
    title: 'Pixel 3',
  },
  {
    value: 'Pixel 3a',
    title: 'Pixel 3a',
  },
  {
    value: 'Pixel 3XL',
    title: 'Pixel 3XL',
  },
  {
    value: 'Pixel 3a XL',
    title: 'Pixel 3a XL',
  },
  { divider: true },
  { header: 'Oppo' },
  {
    value: 'Oppo Find X3 Pro',
    title: 'Oppo Find X3 Pro',
  },
  {
    value: 'Oppo Reno 5 A',
    title: 'Oppo Reno 5 A',
  },
  {
    value: 'Oppo A55s 5G',
    title: 'Oppo A55s 5G',
  },
  { divider: true },
  { header: 'Aquos' },
  {
    value: 'AQUOS sense4 lite',
    title: 'AQUOS sense4 lite',
  },
  { divider: true },
  { header: 'Nuu Mobile' },
  {
    value: 'NUU X5',
    title: 'NUU X5',
  },
  { divider: true },
  { header: 'Panasonic' },
  {
    value: 'Panasonic Toughbook G2',
    title: 'Panasonic Toughbook G2',
  },
  {
    value: 'Panasonic Toughbook 55',
    title: 'Panasonic Toughbook 55',
  },
  { divider: true },
  { header: 'Rakuten' },
  {
    value: 'Rakuten Mini',
    title: 'Rakuten Mini',
  },
  {
    value: 'Rakuten BigS',
    title: 'Rakuten BigS',
  },
  {
    value: 'Rakuten Big',
    title: 'Rakuten Big',
  },
  { divider: true },
  { header: 'Microsoft' },
  {
    value: 'Surface Duo',
    title: 'Surface Duo',
  },
  {
    value: 'Surface Go 2',
    title: 'Surface Go 2',
  },
  {
    value: 'Surface Go 3',
    title: 'Surface Go 3',
  },
  {
    value: 'New Surface Pro 7+',
    title: 'New Surface Pro 7+',
  },
  {
    value: 'Surface Pro LTE',
    title: 'Surface Pro LTE',
  },
  {
    value: 'Surface Pro X',
    title: 'Surface Pro X',
  },
  {
    value: 'Surface Pro 5 LTE Advanced (Ubigi est déjà préinstallé)',
    title: 'Surface Pro 5 LTE Advanced (Ubigi est déjà préinstallé)',
  },
  {
    value: 'Surface Pro 8',
    title: 'Surface Pro 8',
  },
  { divider: true },
  { header: 'Gemini' },
  {
    value: 'PDA Gemini',
    title: 'PDA Gemini',
  },
  { divider: true },
  { header: 'Autre' },
  {
    value: 'autre',
    title: 'Autre',
  },
];

export default phone_types;
