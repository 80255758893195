<template>
  <div style="width: 100%">
    <mv-header />
    <v-main>
      <slot />
    </v-main>
    <mv-footer />
  </div>
</template>

<script>
import TheHeader from "../components/HeaderComponent.vue";
import TheFooter from "../components/FooterComponent.vue";
export default {
  name: "MainLayout",
  props: {},
  data: () => ({}),
  components: {
    "mv-header": TheHeader,
    "mv-footer": TheFooter,
  },
  created() {},

  methods: {},
};
</script>
<style></style>
